<template>
  <!-- style="background: #F8F9FA" -->
  <BasePage :loading="loading" :overlayValue="cartGetter.loadingCart">
    <Container>
      <div>
        <v-row v-if="course && !loading">
          <v-col cols="12" md="9">
            <v-sheet class="pa-5">
              <div>
                <v-img
                  height="300"
                  :src="course.featured_image.data.full_url"
                  class="mb-5 hidden-md-and-up"
                />
                <h2 class="text-h4">{{ course[lang + 'title'] }}</h2>
                <div style="width: 80%" class="body-1 mb-4">
                  {{ course[lang + 'short_discription'] }}
                </div>
                <!-- <Rating /> -->

                <v-row align="center" class="mx-0">
                  <div class="body-2 font-weight-medium">
                    {{ $t('createdBy') }}
                  </div>
                  <div class="overline mx-1">
                    {{
                      course.instructor_id.first_name +
                        ' ' +
                        course.instructor_id.last_name
                    }}
                  </div>
                </v-row>

                <div class="d-flex align-center mx-0 my-4">
                  <v-icon small>mdi-web</v-icon>
                  <span class="mx-1">{{
                    course.language_id[lang + 'title']
                  }}</span>
                </div>

                <!-- price -->
                <div class="d-flex flex-row align-center my-4">
                  <div
                    class="before-discount"
                    style="fontSize:14px"
                    v-if="course.before_discount"
                  >
                    <span>USD</span>
                    {{ course.before_discount }}
                    <!-- {{ coaching ? course.price : calcPrice('before_discount') }} -->
                  </div>

                  <div
                    class="text--primary mx-5"
                    style="fontSize:22px; fontWeight: bold"
                  >
                    <span v-if="!isNaN(course.after_discount)">USD</span>
                    {{ course.after_discount }}
                  </div>
                </div>
                <!-- <div class="my-4 display-1">
                  {{ course.currency_id.title + ' ' }}
                  {{ course.after_discount }}
                </div> -->

                <!-- sm screen actions -->
                <div>
                  <ActionBtns
                    :itemInPocket="itemInPocket"
                    :addToCart="addToCart"
                    :cartHandler="cartHandler"
                    :buyNowHandler="buyNowHandler"
                    :attachment="
                      course.attachment && course.attachment.data.full_url
                    "
                    :course="course"
                    :lang="lang"
                  />
                </div>

                <!-- course Goals -->
                <CourseInfo
                  v-if="type == 1 || type == 3"
                  :title="$t('courseGoals')"
                  :sections="course.section_id"
                  icon="mdi-check"
                />

                <!-- course benefits -->
                <CourseInfo
                  :title="$t('programBenefits')"
                  :content="course.features"
                  icon="mdi-check"
                />

                <div
                  v-if="course.video_link"
                  class="mt-10 pa-5"
                  style="background: #fbfbfb;border: 1px solid #dadbca"
                >
                  <iframe
                    :src="course.video_link"
                    width="100%"
                    height="500px"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>

                <!-- course content -->
                <CourseSections
                  v-if="type === '1' || type == '3'"
                  :sections="course.section_id"
                />

                <!-- about course -->
                <CourseInfo
                  :title="
                    type == 1 || type == 3
                      ? $t('aboutCourse')
                      : $t('aboutIndicator')
                  "
                  :text="course[lang + 'about']"
                />

                <!-- Instructor -->
                <div class="mt-10 pa-6">
                  <h4
                    class="text-h6 font-weight-bold green--text text--darken-2  mb-5"
                  >
                    {{
                      type == 1
                        ? $t('courseSupervisor')
                        : $t('indicatorSupervisor')
                    }}
                  </h4>
                  <div class="mb-3">
                    <h3 class="text-h5">
                      {{
                        course.instructor_id.first_name +
                          ' ' +
                          course.instructor_id.last_name
                      }}
                    </h3>
                    <h4 class="subtitle-1">{{ $t('job') }}</h4>
                  </div>
                  <v-row>
                    <v-col cols="2">
                      <v-img
                        v-if="course.instructor_id.avatar"
                        style="width: 100%"
                        :src="course.instructor_id.avatar.data.full_url"
                      />
                      <v-icon v-else style="fontSize:80px">
                        mdi-account-circle
                      </v-icon>
                    </v-col>
                    <v-col cols="10">
                      <div
                        v-for="(item, idx) in course.instructor_id.instructors"
                        :key="idx"
                        class="my-2 d-flex align-center"
                      >
                        <v-icon style="fontSize:20px">mdi-check</v-icon>
                        <div class="body-2 mx-2">
                          {{ item[lang + 'about'] }}
                        </div>
                      </div>
                      <!-- <div v-html="course.instructor_id.about"></div> -->
                    </v-col>
                  </v-row>
                  <div class="mt-5">
                    <div
                      class="body-2"
                      v-html="course.instructor_id[lang + 'about']"
                    ></div>
                    <!-- <h2 class="h5">{{ $t('aboutInstructor') }}</h2>
                    <div
                      v-for="(item, idx) in course.instructor_id.instructors"
                      :key="idx"
                      class="my-2 d-flex align-center"
                    >
                      <v-icon style="fontSize:20px">mdi-check</v-icon>
                      <div class="body-2 mx-2">{{ item.about }}</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </v-sheet>
          </v-col>
          <!-- sidebar -->
          <v-col cols="3" class="hidden-sm-and-down">
            <v-sheet style="boxShadow: 0px 1px 5px .5px rgba(0,0,0,.2)">
              <div class="white pa-1">
                <v-img
                  height="200"
                  :src="course.featured_image.data.full_url"
                />
              </div>
              <div class="pa-5">
                <v-btn
                  elevation="0"
                  height="50"
                  :color="itemInPocket('favorite') ? 'secondary' : 'primary'"
                  block
                  outlined
                  @click="cartHandler('favorite')"
                >
                  <span>{{ $t('wishlist') }}</span>
                  <v-icon class="mx-1">
                    {{
                      itemInPocket('favorite')
                        ? 'mdi-heart'
                        : 'mdi-heart-outline'
                    }}</v-icon
                  >
                </v-btn>
                <!-- <v-btn
                  elevation="0"
                  style="width: 100%;height:50px"
                  :color="itemInPocket('cart') ? 'secondary' : 'primary'"
                  class="my-3"
                  outlined
                  @click="cartHandler('cart')"
                  ><span>{{ $t('cart') }}</span>

                  <v-icon class="mx-1">{{
                    itemInPocket('cart') ? 'mdi-cart-remove' : 'mdi-cart-plus'
                  }}</v-icon>
                </v-btn> -->

                <!-- <router-link to="/cart" v-slot="{ navigate }">
                  <v-btn
                    elevation="0"
                    style="width: 100%;height:50px"
                    color="warning"
                    @click="navigate"
                    >{{ $t('goToCart') }}</v-btn
                  >
                </router-link> -->

                <!-- :to="`/payment/${course.id}`" -->
                <v-btn
                  @click="buyNowHandler"
                  elevation="0"
                  style="width: 100%;height:50px"
                  color="secondary"
                  class="my-3"
                  outlined
                  v-if="!isNaN(course.after_discount)"
                  >{{ $t('buyNow') }}</v-btn
                >
                <!-- <router-link
                  :to="`/payment/${course.id}`"
                  v-slot="{ navigate }"
                >
                  <v-btn
                    elevation="0"
                    style="width: 100%;height:50px"
                    color="primary"
                    class="my-3"
                    outlined
                    @click="navigate"
                    >{{ $t('buyNow') }}</v-btn
                  >
                </router-link> -->

                <!-- <div v-if="type == 1" class="mt-5">
                  <h2 class="h5">{{ $t('courseIncludes') }}</h2>
                  <div
                    v-for="(item, idx) in content"
                    :key="idx"
                    class="my-2 d-flex align-center"
                  >
                    <v-icon style="fontSize:20px">{{ item.icon }}</v-icon>
                    <div class="body-2 mx-2">{{ item[lang + 'title'] }}</div>
                  </div>
                </div> -->

                <!-- <div class="d-flex align-center justify-center mt-5" dir="ltr">
                  <v-text-field
                    outlined
                    dense
                    :label="$t('enterCoupon')"
                    class="rounded-r-0"
                    style="width: 50%;height: 40px"
                  />
                  <v-btn
                    class="rounded-l-0"
                    color="primary"
                    style="width: 20%;height: 40px"
                    elevation="0"
                    link
                    >{{ $t('send') }}</v-btn
                  >
                </div> -->
                <!-- <v-btn
                  class="mt-8"
                  color="primary"
                  style="width:95%"
                  link
                  :to="`/quiz/${course.id}`"
                  >Content</v-btn
                > -->
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <Auth
        v-if="showAuthForm"
        :dialog="showAuthForm"
        @closeDialog="showAuthForm = false"
      />
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['id'],
  components: {
    // Rating: () => import('@/components/Rating'),
    CourseInfo: () => import('@/components/CourseInfo'),
    CourseSections: () => import('@/components/CourseSections'),
    ActionBtns: () => import('./comp/ActionBtns'),
    Auth: () => import('@/components/Auth'),
    // Dialog: () => import('@/components/Dialog'),
    // CouponView: () => import('./comp/CouponView'),
  },
  data() {
    return {
      loading: false,
      course: null,
      selectedItem: 0,
      selectedLecture: 0,
      type: this.$route.query.type,
      lang: this.$root.lang == 'en' ? 'en' : '',
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
      content: [
        {
          icon: 'mdi-youtube-tv',
          title: '54 hours on-demand video',
        },
        {
          icon: 'mdi-file-outline',
          title: '27 articles',
        },
        {
          icon: 'mdi-folder-download',
          title: '277 downloadable resources',
        },
        {
          icon: 'mdi-infinity',
          title: 'Full lifetime access',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Access on mobile and TV',
        },
        {
          icon: 'mdi-seal',
          title: 'Certificate of completion',
        },
      ],
      actions: [
        {
          text: 'addToFavorite',
          icon:
            this.course && this.itemInPocket()
              ? 'mdi-heart'
              : 'mdi-heart-outline',
          onClick: "addToCart('favorite')",
        },
        {
          text: 'share',
          icon: 'mdi-share',
          onClick: 'shareCourse()',
        },
        {
          text: 'giftCourse',
          onClick: 'giftCourse()',
        },
        // {
        //   text: 'applyCoupon',
        // },
      ],
      showCouponView: false,
      showAuthForm: false,
    };
  },
  methods: {
    ...mapActions(['addToCartAction', 'removeCartItemAction']),

    addToCart(type) {
      if (this.userGetter?.currentUser) {
        this.addToCartAction({ productId: this.course.id, type });
      } else {
        this.showAuthForm = true;
      }
    },
    async getCourseDetails() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/courses?fields=*,currency_id.title,section_id.*,section_id.lecture_id.*,featured_image.data.full_url,instructor_id.avatar.data.full_url,attachment.data.full_url,instructor_id.first_name,instructor_id.last_name,instructor_id.about,instructor_id.instructors.about,language_id.title,features.feature_id.id,features.feature_id.title,features.feature_id.entitle&filter[id]=${this.id}`
        );
        this.course = data.data[0];
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    shareCourse() {
      console.log('share course handler');
    },
    giftCourse() {
      console.log('gift course handler');
    },
    actionHandler(action) {
      if (action.text == 'addToFavorite') this.addToCart('favorite');
      else if (action.text == 'share') this.shareCourse();
      else if (action.text == 'giftCourse') this.giftCourse();
      // console.log('handler', this[action.onClick]);
      // action.onClick();
    },
    itemInPocket(pocket) {
      if (this.userGetter.currentUser) {
        return this.cartGetter[pocket]?.find(
          (item) => item.course_id.id == this.course.id
        );
      }
    },
    cartHandler(type) {
      if (this.userGetter.currentUser) {
        // if (type == 'cart') {
        //   this.addToCartAction({ productId: this.course.id, type });
        // } else if (type == 'favorite') {
        //   }
        const productExists = this.cartGetter[type]?.find(
          (item) => item.course_id.id == this.course.id
        );
        productExists
          ? this.removeCartItemAction({ productId: productExists.id, type })
          : this.addToCartAction({ productId: this.course.id, type });
      } else {
        this.showAuthForm = true;
      }
    },
    buyNowHandler() {
      if (this.userGetter.currentUser)
        this.$router.push(`/payment/${this.course.id}`);
      else this.showAuthForm = true;
    },
  },
  computed: {
    ...mapGetters(['userGetter', 'cartGetter']),
  },
  created() {
    this.getCourseDetails();
    console.log('test', window.location.origin);
  },
};
</script>

<style>
.before-discount {
  text-decoration: line-through;
  color: var(--v-error-base);
}
</style>
